import React, { useContext, useEffect } from 'react'

import { UserContext, Stepper, IStep } from '@gsp/gusto-front-common'
import { User as TelematUser } from '@gsp/gusto-telemat-api-model'

import BondContext from '../../context/BondContext'

import { callTelematCompanyAPI } from '../../../services/telematCompany'

import QuotationDetail from './QuotationDetail/QuotationDetail'
import QuotationType from './QuotationType/QuotationType'
import QuotationRequestByEmail from './QuotationRequestByEmail/QuotationRequestByEmail'
import CustomerDetails from './CustomerDetails/CustomerDetails'

import './style.css'

const steps: IStep[] = [
  {
    component: QuotationType,
    customControls: true,
    id: 1,
    labelKey: 'fast-quotation-step-type',
  },
  {
    component: QuotationDetail,
    id: 2,
    labelKey: 'fast-quotation-step-estimation',
  },
  {
    component: CustomerDetails,
    customControls: true,
    id: 3,
    labelKey: 'fast-quotation-step-get-my-quote',
  },
  {
    component: QuotationRequestByEmail,
    id: 4,
    labelKey: 'fast-quotation-step-subscription',
  },
]

const FastQuotation = () => {
  const { bond, setBond } = useContext(BondContext)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user.telematUser && user.telematUser.telematUserId && user.telematUser.telematToken) {
      callTelematCompanyAPI(user.telematUser.telematUserId, user.telematUser.telematToken)
        .then((telematUserInfo: TelematUser) => {
          setBond({ ...bond, telematUserInfo })
        })
        .catch(() => {
          setBond({
            ...bond,
            telematUserInfo: undefined,
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.telematUser])

  return <Stepper steps={steps} initialStep={0} sharedContext={{}} />
}
export default FastQuotation
