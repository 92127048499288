import React from 'react'
import { FormattedMessage } from 'react-intl'
import './style.css'

const GuarantyExplanation = () => (
  <div className="guarantee-explanation">
    <div className="guarantee-explanation__container">
      <h1 className="guarantee-explanation__title">
        <FormattedMessage id="guarantee-explanation-title" />
      </h1>
      <p>
        <FormattedMessage id="guarantee-explanation-1" />
      </p>
      <p>
        <FormattedMessage id="guarantee-explanation-2" />
      </p>
      <p>
        <FormattedMessage id="guarantee-explanation-3" />
      </p>
    </div>
  </div>
)

export default GuarantyExplanation
