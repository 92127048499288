import { BondInformation, EmailReceipt } from '@gsp/gusto-telemat-api-model'

import apiUrls from './apiUrls'

export interface QuotationRequestByEmailPayload extends BondInformation {
  locale: string
  telematUserId?: string
  telematToken?: string
}

export const callQuotationRequestByEmailAPI = async ({
  amount,
  bondTypeCode,
  companyName,
  duration,
  contactEmail,
  firstName,
  fiscalCode,
  lastName,
  locale,
  priceIndication,
  telematUserId,
  telematToken,
  telephone,
}: QuotationRequestByEmailPayload): Promise<EmailReceipt | null> => {
  const bondSubscriptionByEmailPayload = {
    amount,
    bondTypeCode,
    companyName,
    duration,
    contactEmail,
    firstName,
    fiscalCode,
    lastName,
    locale,
    priceIndication,
    telephone,
  }
  if (telematToken && telematUserId) {
    const response = await fetch(apiUrls.quotationRequestByEmail(), {
      method: 'POST',
      headers: {
        'Accept-Language': locale,
        'Telemat-User': telematUserId,
        'Telemat-Token': telematToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bondSubscriptionByEmailPayload),
    })
    return response.json()
  } else {
    // TODO: implement axios post to intermediary user
    return Promise.resolve(null)
  }
}
