import React, { useContext, useState, useCallback, ReactNode } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Checkbox, StepComponent, isValidEmail } from '@gsp/gusto-front-common'
import { BondTypeCode } from '@gsp/gusto-telemat-api-model'

import BondContext from '../../../context/BondContext'

import QuotationEstimation from './QuotationEstimation/QuotationEstimation'
import { validateMonthsDurations, validateDaysDuration, validateAmount } from '../QuotationDetail/QuotationDetail'
import { isValidItalianPhoneNumber } from '../../../../services/validator'

const CustomerDetails = ({ goToNextStep, restartStepper }: StepComponent) => {
  const intl = useIntl()
  const bondContext = useContext(BondContext)
  const { bond } = bondContext

  const [hasSearched, setHasSearched] = useState(false)
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('+39')
  const [email, setEmail] = useState('')
  const [hasAcceptedContact, setHasAcceptedContact] = useState(false)

  const [hasFinishedQuotation, setHasFinishedQuotation] = useState(false)

  const [errorEmail, setErrorEmail] = useState(false)
  const handleEmailChange = useCallback(
    (value: string) => {
      const isValid = isValidEmail(value)
      setErrorEmail(!isValid)
      setEmail(value)
    },
    [setEmail, setErrorEmail]
  )

  const [errorPhone, setErrorPhone] = useState(false)
  const handlePhoneNumberChange = useCallback(
    (value: string) => {
      const isValid = isValidItalianPhoneNumber(value)
      setErrorPhone(!isValid)
      setPhoneNumber(value)
    },
    [setPhoneNumber, setErrorPhone]
  )

  const toggleHasAcceptedContact = () => setHasAcceptedContact(!hasAcceptedContact)

  const { amount } = bond
  const durationDays = bond.duration.days
  const durationMonths = bond.duration.months

  const privacyNoticeLink = (...chunks: Array<ReactNode>) => (
    <a
      target="_blank"
      className="quotation-contact__privacy-notice"
      href={intl.formatMessage({
        id: 'quotation-contact-checkbox-notice-url',
      })}
      rel="noreferrer"
      onClick={event => event.stopPropagation()}
    >
      {chunks}
    </a>
  )

  return (
    <>
      <div data-testid="customer-detail" className="quotation-contact">
        <div className="quotation-contact-data mb-3">
          <FormattedMessage id="quotation-personal-data" />
        </div>
        <div className="quotation-contact__field">
          <Input
            dataTestid="firstName-input"
            value={firstName}
            changeValue={setFirstName}
            labelKey="first-name"
            isRequired
          />
        </div>
        <div className="quotation-contact__field">
          <Input
            dataTestid="lastName-input"
            value={lastName}
            changeValue={setLastName}
            labelKey="last-name"
            isRequired
          />
        </div>
        <div className="quotation-contact__field">
          <Input
            dataTestid="email-input"
            value={email}
            changeValue={handleEmailChange}
            labelKey="user-email-label"
            defaultError={errorEmail ? 'email-invalid' : ''}
            isRequired
          />
        </div>
        <div className="quotation-contact__field">
          <Input
            dataTestid="phone-number-input"
            value={phoneNumber}
            changeValue={handlePhoneNumberChange}
            labelKey="user-phone-number-label"
            defaultError={errorPhone ? 'phone-number-invalid' : ''}
            isRequired
          />
        </div>
        <div className="quotation-contact-checkbox">
          <Checkbox
            dataTestid="quotation-contact-checkbox"
            labelKey="quotation-contact-checkbox"
            labelValues={{
              privacyNoticeLink,
            }}
            changeValue={toggleHasAcceptedContact}
            mandatory
          />
        </div>
      </div>
      <QuotationEstimation
        goToNextStep={goToNextStep}
        setBondEstimation={(estimation: number) => {
          bondContext.setBond({
            ...bond,
            estimation,
          })
        }}
        isValidAmount={validateAmount(amount.value)}
        isValidDuration={
          bondContext.bond.bondType === BondTypeCode.BID
            ? validateDaysDuration(durationDays)
            : validateMonthsDurations(durationMonths)
        }
        email={email}
        lastName={lastName}
        firstName={firstName}
        phoneNumber={phoneNumber}
        hasAcceptedContact={hasAcceptedContact}
        restartStepper={restartStepper}
        hasSearched={hasSearched}
        setHasSearched={setHasSearched}
        hasFinishedQuotation={hasFinishedQuotation}
        setHasFinishedQuotation={setHasFinishedQuotation}
      />
    </>
  )
}

export default CustomerDetails
