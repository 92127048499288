import React, { useContext, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, StepComponent } from '@gsp/gusto-front-common'
import { BondTypeCode } from '@gsp/gusto-telemat-api-model'

import BondContext from '../../../context/BondContext'

const QuotationType = ({ goToNextStep, setStepCompleted }: StepComponent) => {
  const bondContext = useContext(BondContext)
  const selectBondType = useCallback(
    (bondType: BondTypeCode) => {
      bondContext.setBond({
        ...bondContext.bond,
        bondType,
      })
      setStepCompleted(true)
      goToNextStep()
    },
    [bondContext, setStepCompleted, goToNextStep]
  )

  const br = () => <br />

  return (
    <>
      <div data-testid="quotation-type">
        <div className="quotation-type__title" data-testid="quotation-type-title">
          {bondContext.bond.telematUserInfo ? (
            <FormattedMessage
              data-testid="quotation-type-name-title"
              id="fast-quotation-type-title"
              values={{
                name: bondContext.bond.telematUserInfo.account?.name ?? '',
              }}
            />
          ) : (
            <FormattedMessage id="fast-quotation-type-title-preload" />
          )}
        </div>
        <div className="quotation-type">
          <div className="quotation-type__option--left">
            <Button
              dataTestid="button-bid"
              i18nKey="quotation-type-bid"
              subtitleI18nKey="quotation-type-bid-subtitle"
              type="secondary-big"
              onClick={() => selectBondType(BondTypeCode.BID)}
            />
            <div className="quotation-type__description--left">
              <FormattedMessage
                id="quotation-type-bid-description"
                values={{
                  br,
                }}
              />
            </div>
          </div>
          <div className="quotation-type__option--right">
            <Button
              dataTestid="button-performance"
              i18nKey="quotation-type-performance"
              subtitleI18nKey="quotation-type-performance-subtitle"
              type="secondary-big"
              onClick={() => selectBondType(BondTypeCode.PERFORMANCE)}
            />
            <div className="quotation-type__description--right">
              <FormattedMessage
                id="quotation-type-performance-description"
                values={{
                  br,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="quotation-type__not-found">
        <div>
          <FormattedMessage id="quotation-estimation-not-found" />
        </div>
        <a className="quotation-type__link" target="blank" href="https://www.allianz-trade.com/it_IT/contattaci.html">
          <svg
            className="quotation-type__arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
          </svg>

          <FormattedMessage id="quotation-estimation-another-type" />
        </a>
      </div>
    </>
  )
}
export default QuotationType
