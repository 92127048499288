import React, { useState, useCallback, useContext } from 'react'
import { LanguageContext, CookieContext, Language, getCookie, setCookie } from '@gsp/gusto-front-common'
import { getUserLang, getMessages, getLocaleCode } from '../../i18n'
import { IntlProvider } from 'react-intl'
import TelematLanguage from '../../i18n/TelematLanguage'

const initialLanguage = getUserLang(getCookie('userLang') || navigator.language.slice(0, 2))

export const LanguageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { preferences } = useContext(CookieContext)
  const [userLang, setUserLang] = useState(initialLanguage)
  const [localeCode, setLocaleCode] = useState(getLocaleCode(initialLanguage))
  const [messages, setMessages] = useState(getMessages(initialLanguage))
  const refreshLanguage = useCallback(
    (language: Language) => {
      if (preferences.permanent) {
        setCookie('userLang', language)
      }
      setUserLang(language as TelematLanguage)
      setLocaleCode(getLocaleCode(language as TelematLanguage))
      setMessages(getMessages(language as TelematLanguage))
    },
    [preferences.permanent]
  )

  return (
    <LanguageContext.Provider
      value={{
        userLang,
        localeCode,
        messages,
        refreshLang: refreshLanguage,
      }}
    >
      <IntlProvider locale={userLang} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
