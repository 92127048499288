import React from 'react'
import { FormattedMessage } from 'react-intl'

import FastQuotation from './FastQuotation/FastQuotation'
import GuarantyExplanation from './GuarantyExplanation/GuarantyExplanation'
import OurOffer from './OurOffer/OurOffer'

import './style.scss'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const br = () => <br />

const Telemat = () => {
  return (
    <>
      <Header />
      <div>
        <div data-testid="telemat" className="landing">
          <div className="landing-quotation">
            <FastQuotation />
          </div>
          <div className="landing-description">
            <section className="landing__cover">
              <h2 className="landing__title">
                <FormattedMessage
                  id="landing-subtitle"
                  values={{
                    br,
                  }}
                />
              </h2>
            </section>
            <GuarantyExplanation />
            <OurOffer />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Telemat
