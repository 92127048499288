import enMessages from './en_UK.json'
import ITelematLocale from './ITelematLocale'
import itMessages from './it_IT.json'
import TelematLanguage from './TelematLanguage'

export const telematLanguages: ITelematLocale = {
  acceptedLanguages: ['it', 'en'],
  defaultLanguage: 'it',
}

const messagesList = {
  en: enMessages,
  it: itMessages,
}

const localeCodes = {
  en: 'en-EN',
  it: 'it-IT',
}

export const getUserLang = (detectedLang: string) => {
  const userLang: TelematLanguage = telematLanguages.acceptedLanguages.includes(detectedLang as TelematLanguage)
    ? (detectedLang as TelematLanguage)
    : telematLanguages.defaultLanguage

  return userLang
}

export function getLocaleCode(language: TelematLanguage) {
  return localeCodes[language]
}

export function getMessages(language: TelematLanguage) {
  return messagesList[language]
}
