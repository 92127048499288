import React, { useContext, useState, useEffect, useCallback } from 'react'
import { NumberInput, StepComponent } from '@gsp/gusto-front-common'
import { BondTypeCode } from '@gsp/gusto-telemat-api-model'

import BondContext from '../../../context/BondContext'

import './style.scss'

const defaultDurationDays = 180
const defaultDurationMonths = 1

// no more than x days (maxDurationDays)
const maxDurationDays = 240
export function validateDaysDuration(durationDays: number): boolean {
  return durationDays > 0 && durationDays <= maxDurationDays
}

// amount is positive
export function validateAmount(amount: number): boolean {
  return amount >= 1
}

// not greater than 2 years
export function validateMonthsDurations(month: number) {
  return month >= 1 && month <= 24
}

const QuotationDetail = ({ setStepCompleted }: StepComponent) => {
  const { bond, setBond } = useContext(BondContext)

  const [durationDays, setDurationDays] = useState(bond.bondType === BondTypeCode.BID ? defaultDurationDays : 0)
  const [amount, setAmount] = useState(bond.amount)
  const [durationMonths, setDurationMonths] = useState(
    bond.bondType === BondTypeCode.PERFORMANCE ? defaultDurationMonths : 0
  )

  const [amountError, setAmountError] = useState(false)
  const updateAmount = useCallback(
    (value: string) => {
      const nb = Number(value)
      setAmountError(!validateAmount(nb))
      setAmount({
        ...amount,
        value: nb,
      })
    },
    [setAmount, setAmountError]
  )

  const [durationDaysError, setDurationDaysError] = useState(false)
  const updateDurationDays = useCallback(
    (value: string) => {
      const nb = Number(value)
      setDurationDaysError(!validateDaysDuration(nb))
      setDurationDays(nb)
    },
    [setDurationDays, setDurationDaysError]
  )

  const [durationMonthsError, setDurationMonthsError] = useState(false)
  const updateDurationMonths = useCallback(
    (value: string) => {
      const nb = Number(value)
      setDurationMonthsError(!validateMonthsDurations(nb))
      setDurationMonths(nb)
    },
    [setDurationMonths, setDurationMonthsError]
  )

  useEffect(() => {
    const isValidDuration =
      bond.bondType === BondTypeCode.BID ? validateDaysDuration(durationDays) : validateMonthsDurations(durationMonths)

    setStepCompleted(isValidDuration && validateAmount(amount.value))
  }, [durationDays, durationMonths, amount, bond.bondType, setStepCompleted])

  useEffect(() => {
    setBond({
      ...bond,
      amount,
      duration: {
        ...bond.duration,
        days: durationDays,
        months: durationMonths,
      },
    })
    // eslint-disable-next-line
  }, [durationDays, durationMonths, amount])

  return (
    <div data-testid="quotation-detail" className="quotation-detail">
      <NumberInput
        dataTestid="bond-amount"
        labelKey={`quotation-detail-amount-${bond.bondType === BondTypeCode.BID ? 'bid' : 'performance'}`}
        value={String(amount.value)}
        changeValue={updateAmount}
        isRequired={true}
        defaultError={amountError ? 'invalid-amount' : ''}
        validateBeforeFirstBlur={true}
      />
      <NumberInput
        dataTestid="quotation-duration"
        labelKey={`quotation-detail-duration-${bond.bondType === BondTypeCode.BID ? 'bid' : 'performance'}`}
        value={bond.bondType === BondTypeCode.BID ? durationDays.toString() : durationMonths.toString()}
        changeValue={bond.bondType === BondTypeCode.BID ? updateDurationDays : updateDurationMonths}
        isRequired={true}
        defaultError={
          durationDaysError
            ? {
                key: 'invalid-days-duration',
                values: { maxDuration: maxDurationDays },
              }
            : durationMonthsError
            ? 'invalid-year'
            : ''
        }
        validateBeforeFirstBlur={true}
      />
    </div>
  )
}
export default QuotationDetail
