import { CurrencyCode, QuotationInformation, Pricing } from '@gsp/gusto-telemat-api-model'

import apiUrls from './apiUrls'

interface PriceIndicationPayload extends QuotationInformation {
  telematUserId?: string
  telematToken?: string
}

const fetchPriceIndication = async ({
  amount,
  duration,
  telematUserId,
  telematToken,
  bondTypeCode,
}: PriceIndicationPayload): Promise<Pricing> => {
  const fetchPriceIndicationPayload = {
    amount,
    bondTypeCode,
    duration,
  }

  if (telematToken && telematUserId) {
    const response = await fetch(`${apiUrls.telematPriceIndication()}`, {
      method: 'POST',
      headers: {
        'Telemat-Token': telematToken,
        'Telemat-User': telematUserId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fetchPriceIndicationPayload),
    })
    return response.json()
  } else {
    return {
      // TODO: removed mocked data and implement axios post to intermediary user
      grossPremiumAmount: { value: 50, currencyCode: CurrencyCode.EUR },
      premiumAmount: { value: 50, currencyCode: CurrencyCode.EUR },
      taxAmount: { value: 50, currencyCode: CurrencyCode.EUR },
    }
  }
}

export const callPriceIndicationAPI = ({
  amount,
  telematUserId,
  duration,
  telematToken,
  bondTypeCode,
}: PriceIndicationPayload): Promise<Pricing> => {
  return fetchPriceIndication({
    amount,
    bondTypeCode,
    duration,
    telematToken,
    telematUserId,
  })
}
