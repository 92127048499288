import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import './style.css'

const OurOffer = () => (
  <div className="our-offer">
    <div className="our-offer__container">
      <h1 className="our-offer__title">
        <FormattedMessage id="our-offer-title" />
      </h1>
      <p className="our-offer__subtitle">
        <FormattedMessage id="our-offer-subtitle" />
      </p>
      {[
        ['our-offer-bid-bond', 'our-offer-bid-bond-2'],
        ['our-offer-performance', 'our-offer-performance-1'],
        ['our-offer-environmental', 'our-offer-environmental-2'],
        ['our-offer-fiscal', 'our-offer-fiscal-1'],
      ].map(([text, subText]) => (
        <Fragment key={text}>
          <p className="our-offer__type">
            <FormattedMessage id={text} />
          </p>
          <ul>
            <li>
              <FormattedMessage id={subText} />
            </li>
          </ul>
        </Fragment>
      ))}
      <div className="our-offer__list">
        <div className="our-offer__list-text">
          <a className="our-offer__link" target="blank" href="https://www.allianz-trade.com/it_IT/contattaci.html">
            <FormattedMessage id="our-offer-list" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default OurOffer
