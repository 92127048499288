import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from '@myeh/design-system'

import './App.scss'
import '../node_modules/@myeh/design-system/dist/style.css'
import '../node_modules/@myeh/design-system/dist/fonts.css'
import '../node_modules/@myeh/design-system/dist/icons/style.css'

import { GDPRCookieBanner, UserContext, IUserData, isIE11, BrowserNotSupported } from '@gsp/gusto-front-common'

import Dashboard from './components/Dashboard/Dashboard'
import Bond from './models/Bond'

import BondContext, { initBond } from './components/context/BondContext'
import { CookieContextProvider } from './components/context/CookieContextProvider'
import { LanguageContextProvider } from './components/context/LanguageContextProvider'

interface AppState {
  bond: Bond
  userData: IUserData
  ready: boolean
}

export default function App() {
  const [appState, setAppState] = useState<AppState>({
    bond: initBond(),
    userData: {
      authenticated: false,
      isIntermediary: true,
      familyName: '',
      givenName: '',
      id: '',
      login: '',
    },
    ready: false,
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const telematUserId = params.get('telematId')
    const telematToken = params.get('source')

    if (!telematUserId && !telematToken && process.env.REACT_APP_INTERMEDIARIES_URL) {
      window.location.href = `${process.env.REACT_APP_INTERMEDIARIES_URL}/${window.location.pathname}`
    } else {
      // if TELEMAT found in URL set them in userData
      // otherwise check if logged threw IDP service
      setAppState(state => ({
        ...state,
        userData: {
          authenticated: false,
          isIntermediary: false,
          telematUser:
            telematToken && telematUserId
              ? {
                  telematToken,
                  telematUserId,
                }
              : undefined,
          familyName: '',
          givenName: '',
          id: '',
          login: '',
        },
        ready: true,
      }))
    }
  }, [setAppState])

  const setBond = useCallback((newBond: Bond) => {
    setAppState(s => ({
      ...s,
      bond: newBond,
    }))
  }, [])

  const setUser = useCallback((userData: IUserData) => {
    setAppState(s => ({
      ...s,
      userData,
    }))
  }, [])

  const userContextValue = useMemo(() => {
    return {
      user: appState.userData,
      setUser,
    }
  }, [appState.userData, setUser])

  const bondContextValue = useMemo(() => {
    return {
      bond: appState.bond,
      setBond,
    }
  }, [appState.bond, setBond])

  return (
    <ThemeProvider>
      <CookieContextProvider>
        <LanguageContextProvider>
          <BondContext.Provider value={bondContextValue}>
            <UserContext.Provider value={userContextValue}>
              {isIE11() && <BrowserNotSupported />}
              <div className={`App ${isIE11() ? 'ie' : ''}`}>
                <GDPRCookieBanner />
                {appState.ready && <Dashboard />}
              </div>
            </UserContext.Provider>
          </BondContext.Provider>
        </LanguageContextProvider>
      </CookieContextProvider>
    </ThemeProvider>
  )
}
