import React from 'react'
import { BondTypeCode, CurrencyCode } from '@gsp/gusto-telemat-api-model'
import Bond from '../../models/Bond'

const MINIMUM_AMOUNT_VALUE = 5000

export const initBond = (): Bond => ({
  amount: {
    value: MINIMUM_AMOUNT_VALUE,
    currencyCode: CurrencyCode.EUR,
  },
  duration: {
    days: 0,
    months: 0,
  },
  telematUserInfo: undefined,
  bondType: BondTypeCode.BID,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  estimation: 0,
})

const BondContext = React.createContext({
  bond: initBond(),
  setBond: (bond: Bond) => {},
})

export default BondContext
