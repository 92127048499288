import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isIE11 } from '@gsp/gusto-front-common'

import allianzLogo from '../../../resources/allianz-trade-logo-white.png'

import linkedInLogo from '../../../resources/linkedin-logo.svg'
import twitterLogo from '../../../resources/twitter-logo.svg'
import youtubeLogo from '../../../resources/ytube-logo.svg'

import './style.scss'

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div data-testid="corporate-links" className="footer__links">
          <a href={'https://www.allianz-trade.com/'}>
            <img className="footer__logo" src={allianzLogo} alt="eh-allianz logo" />
          </a>
        </div>
        <div className="footer__links">
          <a className="footer__link" href="https://www.allianz-trade.com/it_IT/informativa-privacy.html">
            <FormattedMessage id="footer-link-privacy" />
          </a>
          <a className="footer__link" href="https://www.allianz-trade.com/it_IT/informativa-legale.html">
            <FormattedMessage id="footer-link-legal" />
          </a>
          <a className="footer__link" href="https://www.allianz-trade.com/it_IT/gestione-reclami.html">
            <FormattedMessage id="footer-link-regulation" />
          </a>
        </div>
        <div className="footer__social-networks">
          <a href={'https://www.linkedin.com/company/allianz-trade-italia/'}>
            <img src={linkedInLogo} alt="linkedIn logo" />
          </a>
          <a href={'https://twitter.com/AllianzTrade'}>
            <img src={twitterLogo} alt="twitter logo" />
          </a>
          <a href={'https://www.youtube.com/allianz-trade-italia/'}>
            <img src={youtubeLogo} alt="youtube logo" />
          </a>
        </div>
        {isIE11() && <div className="footer--fix-ie-align-center" />}
      </div>
    </>
  )
}

export default Footer
