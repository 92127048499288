import { User as TelematUser } from '@gsp/gusto-telemat-api-model'

import apiUrls from './apiUrls'

export const callTelematCompanyAPI = async (telematUserId: string, telematToken: string): Promise<TelematUser> => {
  let response = await fetch(apiUrls.telematUserInfo(telematUserId), {
    headers: {
      'Telemat-Token': telematToken,
    },
  })
  return response.json()
}
