import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Loader, UserContext, StepComponent, LanguageContext } from '@gsp/gusto-front-common'
import { CurrencyCode } from '@gsp/gusto-telemat-api-model'
import BondContext from '../../../context/BondContext'

import { callQuotationRequestByEmailAPI } from '../../../../services/quotationRequestByEmail'
import formatDuration from '../../../../utils/format'

const QuotationRequestByEmail = ({ setStepCompleted }: StepComponent) => {
  const { bond } = useContext(BondContext)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const { user } = useContext(UserContext)
  const { userLang } = useContext(LanguageContext)

  const {
    telematUserInfo,
    amount,
    bondType,
    duration,
    email = '',
    firstName = '',
    lastName = '',
    phoneNumber = '',
  } = bond

  const sendEmail = () => {
    if (!telematUserInfo || !telematUserInfo.account) {
      setError('quotation-error-email')
      setLoading(false)
      return
    }

    if (!user.telematUser || user.isIntermediary) {
      setError('quotation-error-restricted')
      setLoading(false)
      return
    }

    setLoading(true)

    // quotation request by email (TELEMAT only atm)
    callQuotationRequestByEmailAPI({
      amount,
      bondTypeCode: bondType,
      companyName: telematUserInfo.account.name,
      duration: formatDuration(duration),
      contactEmail: email,
      firstName,
      fiscalCode: telematUserInfo.account.fiscalCode,
      lastName,
      locale: userLang,
      priceIndication: {
        currencyCode: CurrencyCode.EUR,
        value: bond.estimation,
      },
      telematToken: user.telematUser.telematToken,
      telematUserId: user.telematUser.telematUserId,
      telephone: phoneNumber,
    })
      .then(() => {
        setStepCompleted(true)
        setLoading(false)
      })
      .catch(e => {
        setError('quotation-error-email')
        setLoading(false)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sendEmail, [])

  const br = () => <br />

  return (
    <div data-testid="quotation-request-subscribe">
      {loading ? (
        <div className="quotation-estimation">
          <Loader isVisible />
        </div>
      ) : error ? (
        <span data-testid="quotation-error" className="quotation-estimation__error">
          <FormattedMessage id={error} />
        </span>
      ) : (
        <>
          <div className="quotation-estimation__confirmation" data-testid="quotation-complete">
            <FormattedMessage
              id="quotation-complete"
              values={{
                email: bond.email,
                br,
              }}
            />
          </div>
          <div className="quotation-estimation__confirmation-disclaimer">
            <FormattedMessage id="quotation-complete-disclaimer" />
          </div>
        </>
      )}
    </div>
  )
}

export default QuotationRequestByEmail
