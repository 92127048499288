import React, { ReactNode, useContext, useState } from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { UserContext, Button, Loader } from '@gsp/gusto-front-common'

import BondContext from '../../../../context/BondContext'

import { callPriceIndicationAPI } from '../../../../../services/priceIndication'
import formatDuration from '../../../../../utils/format'

import './style.scss'

export interface QuotationEstimationProps {
  isValidAmount: boolean
  isValidDuration: boolean
  restartStepper: () => void
  setBondEstimation: (estimation: number) => void
  goToNextStep: () => void
  hasSearched: boolean
  setHasSearched: (newHasSearched: boolean) => void
  email: string
  lastName: string
  firstName: string
  phoneNumber: string
  hasAcceptedContact: boolean
  hasFinishedQuotation: boolean
  setHasFinishedQuotation: (hasFinished: boolean) => void
  intl: IntlShape
}
const QuotationEstimation = ({
  isValidAmount,
  isValidDuration,
  restartStepper,
  setBondEstimation,
  hasSearched,
  setHasSearched,
  email,
  lastName,
  firstName,
  phoneNumber,
  hasAcceptedContact,
  goToNextStep,
}: QuotationEstimationProps) => {
  const bondContext = useContext(BondContext)
  const { amount, duration, bondType } = bondContext.bond

  const [quotationEstimation, setQuotationEstimation] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { user } = useContext(UserContext)

  const getPriceIndication = () => {
    if (loading) {
      return null
    }
    setLoading(true)
    if (!user.telematUser && !user.isIntermediary) {
      setError('quotation-error-restricted')
      setLoading(false)
      return
    }
    // async request to get the price indication (TELEMAT or intermediary)
    callPriceIndicationAPI({
      amount,
      bondTypeCode: bondType,
      duration: formatDuration(duration),
      telematToken: user.telematUser!.telematToken,
      telematUserId: user.telematUser!.telematUserId,
    })
      .then(estimation => {
        setQuotationEstimation(estimation.grossPremiumAmount.value)
        setBondEstimation(estimation.grossPremiumAmount.value)
        setHasSearched(true)
        setError('')
        setLoading(false)
      })
      .catch(() => {
        setError('quotation-error')
        setLoading(false)
      })
  }

  const isInvalid =
    !hasAcceptedContact ||
    !email ||
    !lastName ||
    !firstName ||
    !phoneNumber ||
    phoneNumber === '+39' ||
    !isValidAmount ||
    !isValidDuration

  const shouldDisplayResults = hasSearched

  const priceIndicationButton = (
    <Button
      type="big"
      dataTestid="get-bond-quotation"
      i18nKey="quotation-estimation-load"
      disabled={isInvalid}
      onClick={() => {
        getPriceIndication()
      }}
    />
  )

  const quotationPrice = (...chunks: Array<ReactNode>) => <b className="quotation-price">{chunks}</b>

  return (
    <>
      {loading ? (
        <div className="quotation-estimation">
          <Loader isVisible />
        </div>
      ) : error ? (
        <>
          <span data-testid="quotation-estimation-error" className="quotation-estimation__error">
            <FormattedMessage id={error} />
          </span>
          {priceIndicationButton}
        </>
      ) : shouldDisplayResults ? (
        <div data-testid="quotation-estimation" className="quotation-estimation">
          <hr className="quotation-estimation--separator" />
          <div data-testid="bond-response-test">
            <FormattedMessage
              id={`quotation-estimation-${bondType.toLowerCase()}`}
              values={{
                estimatedPrice: quotationEstimation,
                quotationPrice,
              }}
            />
          </div>
          <div className="quotation-estimation__actions">
            <Button
              dataTestid="quotation-estimation-next-button"
              i18nKey={`quotation-estimation-subscribe-${bondType.toLocaleLowerCase()}`}
              onClick={() => {
                bondContext.setBond({
                  ...bondContext.bond,
                  email,
                  firstName,
                  lastName,
                  phoneNumber,
                })
                goToNextStep()
              }}
              type="big"
            />
          </div>
          <div className="quotation-estimation__disclaimer">
            <FormattedMessage id="quotation-estimation-disclaimer" />
          </div>
        </div>
      ) : (
        <div className="price-indication-button">{priceIndicationButton}</div>
      )}
      <div className="quotation-estimation__change-type" onClick={restartStepper}>
        <FormattedMessage id="quotation-estimation-change-type" />
      </div>
    </>
  )
}
export default injectIntl(QuotationEstimation)
